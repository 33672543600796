<template>
    <div
        class="row justify-center text-xs-center font-medium font-italic color-error mb-1"
    >
        (Mặc định tạo chuyến đi bán vé khi tạo Lệnh)
    </div>

    <div class="row mb-2">
        <DxCheckBox
            text="Lưu vào Danh sách chuyến đi dự kiến"
            v-model="KyLenhTuyChon_ThongTinChung.LuuChuyenDiDuKien"
        />
    </div>
    <div class="row mb-2">
        <DxCheckBox
            v-if="
                ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                    $t('MaCauHinhDoanhNghiep.SuDungQuyTrinhCanBoKiemTraXe')
                ] == 'true'
            "
            v-model="KyLenhTuyChon_ThongTinChung.TuDongKyLenhKhiCanBoXacNhanKiemTraXe"
            text="Tự động ký lệnh khi cán bộ kiểm tra xe xác nhận"
        />
        <DxCheckBox
            v-else
            text="Ký điện tử (Ký số)"
            v-model="KyLenhTuyChon_ThongTinChung.KyDienTu"
        />
    </div>

    <div class="row font-16 font-medium mb-1">Thời gian ký lệnh</div>

    <DxValidationGroup ref="formValidation">
        <div class="row mb-4">
            <div class="xs12">
                <DxDateBox
                    label="Ký từ ngày (*)"
                    labelMode="floating"
                    v-model="KyLenhTuyChon_ThongTinChung.KyTuNgay"
                    ref="KyTuNgay"
                    displayFormat="dd/MM/yyyy"
                    pickerType="rollers"
                    :calendarOptions="{
                        dateSerializationFormat: 'dd/MM/yyyy',
                    }"
                    :use-mask-behavior="true"
                    dropDownButtonTemplate="customIcon"
                    validationMessageMode="always"
                    styling-mode="underlined"
                    :onValueChanged="onValueChangedLayNgay"
                >
                    <template #customIcon="{}">
                        <DxButton icon="mdi mdi-calendar" />
                    </template>

                    <DxValidator>
                        <DxRequiredRule message="Ký từ ngày không được bỏ trống" />
                        <DxRangeRule
                            :max="KyLenhTuyChon_ThongTinChung.KyDenNgay || new Date()"
                            message="Từ ngày không được lớn hơn đến ngày"
                        />
                        <DxRangeRule
                            :max="maxDate"
                            message="Năm nhập không được vượt quá 2999!"
                        />
                        <DxRangeRule
                            :min="minDate"
                            :message="
                                ChoPhepKyLenhNgayCu
                                    ? `Ký từ ngày không được nhỏ hơn 7 ngày từ ngày hiện tại!`
                                    : `Ký từ ngày không được nhỏ hơn ngày hiện tại!`
                            "
                        />
                        <DxCustomRule
                            :validationCallback="validationTuNgayDenNgay"
                            :message="`Khoảng ngày chọn không được lớn hơn ${SoLuongNgayKyLenhToiDa}!`"
                        />
                    </DxValidator>
                </DxDateBox>
            </div>
        </div>

        <div class="row mb-3">
            <div class="xs12">
                <DxDateBox
                    label="Ký đến ngày (*)"
                    labelMode="floating"
                    v-model="KyLenhTuyChon_ThongTinChung.KyDenNgay"
                    ref="KyDenNgay"
                    displayFormat="dd/MM/yyyy"
                    pickerType="rollers"
                    :calendarOptions="{
                        dateSerializationFormat: 'dd/MM/yyyy',
                    }"
                    :use-mask-behavior="true"
                    dropDownButtonTemplate="customIcon"
                    validationMessageMode="always"
                    styling-mode="underlined"
                    autocomplete="off"
                    :onValueChanged="onValueChangedLayNgay"
                >
                    <template #customIcon="{}">
                        <DxButton icon="mdi mdi-calendar" />
                    </template>
                    <DxValidator>
                        <DxRequiredRule message="Ký đến ngày không được bỏ trống" />
                        <DxRangeRule
                            :max="maxDate"
                            message="Năm nhập không được vượt quá 2999!"
                        />
                        <DxRangeRule
                            :min="KyLenhTuyChon_ThongTinChung.KyTuNgay || new Date()"
                            message="Đến ngày không được nhỏ hơn từ ngày"
                        />
                        <DxCustomRule
                            :validationCallback="validationTuNgayDenNgay"
                            :message="`Khoảng ngày chọn không được lớn hơn ${SoLuongNgayKyLenhToiDa}!`"
                        />
                    </DxValidator>
                </DxDateBox>
            </div>
        </div>

        <div class="row font-16 font-medium mb-1">Thông tin chung</div>

        <div class="row mb-1">
            <div class="xs12">
                <DxSelectBox
                    label="Loại lệnh vận chuyển (*)"
                    labelMode="floating"
                    :dataSource="{
                        store: DanhSachLoaiLenh,
                        paginate: true,
                        pageSize: 20,
                    }"
                    value-expr="IdLoaiLenh"
                    display-expr="TenLoaiLenh"
                    styling-mode="underlined"
                    validationMessageMode="always"
                    v-model="KyLenhTuyChon_ThongTinChung.LoaiLenhVanChuyen"
                >
                    <DxValidator>
                        <DxRequiredRule
                            message="Loại lệnh vận chuyển không được bỏ trống"
                        />
                    </DxValidator>
                </DxSelectBox>
                <div
                    class="text-chu-thich"
                    :style="`color: ${textChuThichLoaiLenh.MaMau}`"
                    v-if="KyLenhTuyChon_ThongTinChung.LoaiLenhVanChuyen"
                >
                    {{ textChuThichLoaiLenh.GhiChu }}
                </div>
            </div>
        </div>
    </DxValidationGroup>

    <DxValidationGroup
        ref="formValidationXeNghi"
        v-if="
            KyLenhTuyChon_ThongTinChung.LoaiLenhVanChuyen ==
            $t('TrangThaiLoaiLenh.DiThay')
        "
    >
        <div class="row mb-4">
            <div class="xs12">
                <DxSelectBox
                    v-model:value="KyLenhTuyChon_ThongTinChung.ID_DnvtXe_DuocDiThay"
                    label="Xe nghỉ (*)"
                    labelMode="floating"
                    :dataSource="{
                        store: KyLenhTuyChon_DanhSachPhuongTienTaoLenhTuyChon,
                        paginate: true,
                    }"
                    display-expr="BienKiemSoat"
                    ref="BienKiemSoatXeNghi"
                    value-expr="ID_DnvtXe"
                    :search-enabled="true"
                    :showClearButton="true"
                    styling-mode="underlined"
                    validationMessageMode="always"
                    :onItemClick="GanThongTinXeNghi"
                >
                    <DxValidator>
                        <DxRequiredRule message="Xe nghỉ không được bỏ trống!" />
                        <DxCompareRule
                            :comparisonTarget="
                                () => {
                                    return KyLenhTuyChon_ThongTinChung.ID_DnvtXe;
                                }
                            "
                            comparisonType="!="
                            message="Xe nghỉ không được trùng với xe thay thế!"
                        />
                    </DxValidator>
                </DxSelectBox>
            </div>
        </div>
    </DxValidationGroup>

    <DxValidationGroup ref="formValidationTuyenVanChuyen">
        <div class="row mb-4">
            <div class="xs12">
                <DxSelectBox
                    label="Tuyến vận chuyển (*)"
                    labelMode="floating"
                    :dataSource="{
                        store: KyLenhTuyChon_DanhSachTuyenVanChuyen,
                        paginate: true,
                        pageSize: 20,
                    }"
                    :value-expr="(item) => item"
                    display-expr="TenTuyen"
                    :search-enabled="true"
                    :showClearButton="true"
                    styling-mode="underlined"
                    validationMessageMode="always"
                    :onValueChanged="ChonTuyenVanChuyen"
                    item-template="item"
                >
                    <template #item="{ data }">
                        <div
                            style="
                                white-space: break-spaces;
                                border-bottom: 1px solid #dadce0;
                            "
                        >
                            {{ data ? `${data.TenTuyen}` : "" }}
                        </div>
                    </template>
                    <DxValidator>
                        <DxRequiredRule message="Tuyến vận chuyển không được bỏ trống" />
                    </DxValidator>
                </DxSelectBox>
            </div>
        </div>
    </DxValidationGroup>

    <DxValidationGroup ref="formValidationBKS">
        <div class="row align-center mb-4">
            <div class="xs12">
                <DxSelectBox
                    v-model:value="KyLenhTuyChon_ThongTinChung.ID_DnvtXe"
                    ref="BienKiemSoat"
                    :label="
                        KyLenhTuyChon_ThongTinChung.LoaiLenhVanChuyen !=
                        $t('TrangThaiLoaiLenh.DiThay')
                            ? 'Biển kiểm soát (*)'
                            : 'Xe đi thay (*)'
                    "
                    labelMode="floating"
                    :dataSource="{
                        store: KyLenhTuyChon_DanhSachPhuongTienTaoLenhTuyChon,
                        paginate: true,
                    }"
                    value-expr="ID_DnvtXe"
                    display-expr="BienKiemSoat"
                    :search-enabled="true"
                    :showClearButton="true"
                    styling-mode="underlined"
                    validationMessageMode="always"
                    :onItemClick="GanThongTinPhuongTien"
                    :onValueChanged="onValueChangedBienKiemSoat"
                    field-template="field"
                >
                    <template #field="{ data }">
                        <DxTextBox
                            :value="data ? data.BienKiemSoat : ``"
                            :class="
                                CheckDuDieuKienThongTinXe == 'HetHan'
                                    ? 'color-error-thong-tin-xe'
                                    : CheckDuDieuKienThongTinXe == 'GanHetHan'
                                    ? 'color-warning-thong-tin-xe'
                                    : ''
                            "
                        />
                    </template>
                    <DxValidator
                        ref="validateBienKiemSoat"
                        :onValidated="onValidatedPhuongTien"
                    >
                        <DxRequiredRule
                            :message="
                                KyLenhTuyChon_ThongTinChung.LoaiLenhVanChuyen !=
                                $t('TrangThaiLoaiLenh.DiThay')
                                    ? 'Biển kiểm soát không được bỏ trống'
                                    : 'Xe đi thay không được bỏ trống'
                            "
                        />
                        <!-- <DxCustomRule
                                            :validationCallback="validationXe"
                                            message="Xe nghỉ và xe đi thay không được trùng!"
                                        /> -->

                        <DxCompareRule
                            :comparisonTarget="
                                () => {
                                    return KyLenhTuyChon_ThongTinChung.ID_DnvtXe_DuocDiThay;
                                }
                            "
                            comparisonType="!="
                            message="Xe thay thế không được trùng với xe nghỉ!"
                        />
                    </DxValidator>
                </DxSelectBox>
            </div>

            <DxButton
                type="normal"
                styling-mode="text"
                :icon="`mdi mdi-${openSuaThongTinXe ? `close` : `pencil`}`"
                @click="openSuaThongTinXe = !openSuaThongTinXe"
            />
        </div>
    </DxValidationGroup>

    <DxValidationGroup ref="formValidationHanPhuHieu">
        <div class="row mb-4">
            <div class="xs12">
                <DxDateBox
                    label="Hạn phù hiệu tuyến (*)"
                    labelMode="floating"
                    v-model:value="KyLenhTuyChon_ThongTinChung.HanPhuHieuTuyen"
                    ref="KyDenNgay"
                    displayFormat="dd/MM/yyyy"
                    pickerType="rollers"
                    :calendarOptions="{
                        dateSerializationFormat: 'dd/MM/yyyy',
                    }"
                    :use-mask-behavior="true"
                    dropDownButtonTemplate="customIcon"
                    validationMessageMode="always"
                    styling-mode="underlined"
                    autocomplete="off"
                >
                    <template #customIcon="{}">
                        <DxButton icon="mdi mdi-calendar" />
                    </template>
                    <DxValidator>
                        <DxRequiredRule message="Hạn phù hiệu không được bỏ trống!" />
                        <DxRangeRule
                            :max="maxDate"
                            :message="$i18n.t('DefaultString.MaxDate')"
                        />
                        <DxRangeRule
                            :min="$Helper.Common.getEndDay(new Date())"
                            message="Hạn phù hiệu phải lớn hơn ngày hiện tại!"
                        />
                    </DxValidator>
                </DxDateBox>
            </div>
        </div>
    </DxValidationGroup>

    <div
        class="row transition overflow-hidden"
        :style="`max-height: ${openSuaThongTinXe ? `1000px` : `0px`}`"
    >
        <div class="xs12">
            <DxValidationGroup ref="formValidationThongTinXe">
                <div class="row mb-4">
                    <div class="xs12">
                        <DxDateBox
                            label="Hạn bảo hiểm xe (*)"
                            labelMode="floating"
                            v-model:value="KyLenhTuyChon_ThongTinChung.HanBaoHiemXe"
                            ref="KyDenNgay"
                            displayFormat="dd/MM/yyyy"
                            pickerType="rollers"
                            :calendarOptions="{
                                dateSerializationFormat: 'dd/MM/yyyy',
                            }"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            styling-mode="underlined"
                            autocomplete="off"
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                            <DxValidator>
                                <DxRequiredRule
                                    message="Hạn bảo hiểm xe không được bỏ trống!"
                                />
                                <DxRangeRule
                                    :max="maxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="$Helper.Common.getEndDay(new Date())"
                                    message="Hạn bảo hiểm xe phải lớn hơn ngày hiện tại!"
                                />
                            </DxValidator>
                        </DxDateBox>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="xs12">
                        <DxDateBox
                            label="Hạn đăng kiểm xe (*)"
                            labelMode="floating"
                            v-model:value="KyLenhTuyChon_ThongTinChung.HanDangKiemXe"
                            ref="KyDenNgay"
                            displayFormat="dd/MM/yyyy"
                            pickerType="rollers"
                    :calendarOptions="{
                        dateSerializationFormat: 'dd/MM/yyyy',
                    }"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            styling-mode="underlined"
                            autocomplete="off"
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                            <DxValidator>
                                <DxRequiredRule
                                    message="Hạn đăng kiểm xe không được bỏ trống!"
                                />
                                <DxRangeRule
                                    :max="maxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="$Helper.Common.getEndDay(new Date())"
                                    message="Hạn đăng kiểm phải lớn hơn ngày hiện tại!"
                                />
                            </DxValidator>
                        </DxDateBox>
                    </div>
                </div>
            </DxValidationGroup>
        </div>
    </div>
</template>
<script>
import { DxButton } from "devextreme-vue/button";
import {
    DxCheckBox,
    DxSelectBox,
    DxValidationGroup,
    DxValidator,
    DxDateBox,
    DxTextBox,
} from "devextreme-vue";
import {
    DxRequiredRule,
    DxCompareRule,
    DxRangeRule,
    DxCustomRule,
} from "devextreme-vue/data-grid";
import { mapGetters } from "vuex";

export default {
    components: {
        DxButton,
        DxCompareRule,
        DxRangeRule,
        DxCustomRule,
        DxCheckBox,
        DxValidationGroup,
        DxRequiredRule,
        DxCompareRule,
        DxValidator,
        DxDateBox,
        DxTextBox,
        DxSelectBox,
    },
    props: {},
    data() {
        return {
            LuuChuyenDiDuKien: false,
            openSuaThongTinXe: false,
        };
    },
    computed: {
        ...mapGetters({
            SoLuongNgayKyLenhToiDa: "DVVTLenh/SoLuongNgayKyLenhToiDa",
        }),
        KyLenhTuyChon_ThongTinChung: {
            get() {
                return this.$store.state.LenhTuyChon.KyLenhTuyChon_ThongTinChung || {};
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_ThongTinChung",
                    data: data,
                });
            },
        },
        ChoPhepKyLenhNgayCu() {
            let token = $Core.AuthApi.TokenParsed;
            if (
                token.hasOwnProperty("CauHinhLenh") &&
                token.CauHinhLenh.hasOwnProperty("DuocPhepCapLenhNhoNgayHienTai")
            ) {
                return token.CauHinhLenh.DuocPhepCapLenhNhoNgayHienTai;
            }
            return false;
        },
        maxDate() {
            return this.$store.state.DVVTLenh.maxDate;
        },
        minDate() {
            if (this.ChoPhepKyLenhNgayCu) {
                return this.$Helper.getStartDay(
                    new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7, 0),
                );
            } else {
                return this.$Helper.getStartDay(new Date());
            }
        },
        DanhSachLoaiLenh() {
            return this.$store.state.LenhTuyChon.DanhSachLoaiLenh || [];
        },
        CheckDuDieuKienThongTinXe() {
            return this.$store.getters["LenhTuyChon/CheckDuDieuKienThongTinXe"];
        },
        textChuThichLoaiLenh() {
            let item = this.DanhSachLoaiLenh.find(
                (e) => e.IdLoaiLenh == this.KyLenhTuyChon_ThongTinChung.LoaiLenhVanChuyen,
            );
            return item || {};
        },
        KyLenhTuyChon_DanhSachTuyenVanChuyen() {
            return (
                this.$store.state.LenhTuyChon.KyLenhTuyChon_DanhSachTuyenVanChuyen || []
            );
        },
        KyLenhTuyChon_DanhSachPhuongTienTaoLenhTuyChon() {
            return (
                this.$store.state.LenhTuyChon
                    .KyLenhTuyChon_DanhSachPhuongTienTaoLenhTuyChon || []
            );
        },
        KyLenhTuyChon_DanhSachNgayKyLenh: {
            get() {
                return (
                    this.$store.state.LenhTuyChon.KyLenhTuyChon_DanhSachNgayKyLenh || []
                );
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_DanhSachNgayKyLenh",
                    data: data,
                });
            },
        },
        ChiTietCauHinhDoanhNghiepTheoMaCauHinh() {
            return this.$store.state.DVVTLenh.ChiTietCauHinhDoanhNghiepTheoMaCauHinh;
        },
    },
    watch: {},
    methods: {
        validationTuNgayDenNgay() {
            let TuNgay = new Date(
                this.$Helper.Common.getStartDay(
                    this.KyLenhTuyChon_ThongTinChung.KyTuNgay,
                ),
            ).getTime();
            let DenNgay = new Date(
                this.$Helper.Common.getEndDay(this.KyLenhTuyChon_ThongTinChung.KyDenNgay),
            ).getTime();

            let minusResult = (DenNgay - TuNgay) / (1000 * 60 * 60 * 24);
            if (minusResult > this.SoLuongNgayKyLenhToiDa) return false;
            return true;
        },
        onValueChangedLayNgay() {
            if (!this.validationTuNgayDenNgay()) {
                return;
            }
            this.LayNgayChieuDi();
        },
        LaySoNgayTrongKhoangThoiGianKyLenh(d1, d2) {
            let ms1 = d1.getTime();
            let ms2 = d2.getTime();
            return Math.ceil((ms2 - ms1) / (24 * 60 * 60 * 1000)) + 1;
        },
        async LayNgayChieuDi() {
            try {
                if (
                    this.KyLenhTuyChon_ThongTinChung.KyTuNgay &&
                    this.KyLenhTuyChon_ThongTinChung.KyDenNgay &&
                    this.KyLenhTuyChon_ThongTinChung.KyTuNgay <=
                        this.KyLenhTuyChon_ThongTinChung.KyDenNgay
                ) {
                    this.KyLenhTuyChon_DanhSachNgayKyLenh = [];
                    let KyLenhTuyChon_DanhSachNgayKyLenh = [];
                    let SoNgayTrongKhoangThoiGianKyLenh = this.LaySoNgayTrongKhoangThoiGianKyLenh(
                        this.KyLenhTuyChon_ThongTinChung.KyTuNgay,
                        this.KyLenhTuyChon_ThongTinChung.KyDenNgay,
                    );
                    let KyTuNgay = new Date(this.KyLenhTuyChon_ThongTinChung.KyTuNgay);

                    for (let i = 0; i < SoNgayTrongKhoangThoiGianKyLenh; i++) {
                        let day = new Date(KyTuNgay);
                        let past = new Date(day);
                        past.setDate(day.getDate() - 1);
                        let nextDay = new Date(past);
                        nextDay.setDate(past.getDate() + i);
                        let arrTemp = this.$Helper.Common.formatDate(
                            nextDay.setDate(nextDay.getDate() + 1),
                        ).split("/");
                        KyLenhTuyChon_DanhSachNgayKyLenh.push({
                            STT: i + 1,
                            NgayTrongTuan: nextDay.getDay(),
                            NgayDuong: nextDay,
                            NgayAm: this.$Helper.getLunarDateText(
                                parseInt(arrTemp[0]),
                                parseInt(arrTemp[1]),
                                parseInt(arrTemp[2]),
                            ),
                            Ngay: nextDay,
                        });
                    }

                    this.KyLenhTuyChon_DanhSachNgayKyLenh = KyLenhTuyChon_DanhSachNgayKyLenh;
                }
            } catch (error) {
                console.log(
                    "🚀 ~ file: ThongTinLenhTuyChon.vue:591 ~ LayNgayChieuDi ~ error:",
                    error,
                );
            }
        },
        async ChonTuyenVanChuyen(item) {
            if (item.value) {
                this.KyLenhTuyChon_ThongTinChung.DanhSachLoTrinh =
                    item.value.DanhSachLoTrinh;
                this.KyLenhTuyChon_ThongTinChung.TenTuyen = item.value.TenTuyen;
                this.KyLenhTuyChon_ThongTinChung.ID_DnvtTuyen = item.value.ID_DnvtTuyen;
                this.LayHanPhuHieuTuyen();
                // this.LayCauHinhChinhSuaKhiKyLenhTheoTuyenVaBKS();
                this.emitter.emit("LayCauHinhChinhSuaKhiKyLenhTheoTuyenVaBKS");
            }
        },
        onValueChangedBienKiemSoat(item) {
            this.emitter.emit("LayCauHinhChinhSuaKhiKyLenhTheoTuyenVaBKS");
        },
        GanThongTinPhuongTien(item) {
            this.$refs.formValidationXeNghi?.instance.validate();
            this.$refs.formValidationBKS?.instance.validate();
            this.KyLenhTuyChon_ThongTinChung.BienKiemSoat =
                item.itemData.BienKiemSoat || null;
            this.KyLenhTuyChon_ThongTinChung.HanPhuHieuTuyen =
                item.itemData && item.itemData.HanPhuHieu
                    ? this.$Helper.Common.getEndDay(item.itemData.HanPhuHieu)
                    : null;
            this.KyLenhTuyChon_ThongTinChung.HanBaoHiemXe =
                item.itemData != null
                    ? this.$Helper.Common.getEndDay(item.itemData.HanBaoHiem)
                    : null;
            this.KyLenhTuyChon_ThongTinChung.HanDangKiemXe =
                item.itemData != null
                    ? this.$Helper.Common.getEndDay(item.itemData.HanDangKiem)
                    : null;
            this.KyLenhTuyChon_ThongTinChung.HanBienNhanTheChap =
                item.itemData != null && item.itemData.HanBienNhanTheChap
                    ? this.$Helper.Common.getEndDay(item.itemData.HanBienNhanTheChap)
                    : null;

            this.LayHanPhuHieuTuyen();

            if (this.CheckDuDieuKienThongTinXe == "HetHan") {
                this.openSuaThongTinXe = true;
            } else if (this.CheckDuDieuKienThongTinXe == "DuDieuKien") {
                this.openSuaThongTinXe = false;
            }
        },
        async LayHanPhuHieuTuyen() {
            if (
                (this.KyLenhTuyChon_ThongTinChung.ID_DnvtXe_DuocDiThay ||
                    this.KyLenhTuyChon_ThongTinChung.ID_DnvtXe) &&
                this.KyLenhTuyChon_ThongTinChung.ID_DnvtTuyen
            ) {
                try {
                    this.KyLenhTuyChon_ThongTinChung.HanPhuHieuTuyen = null;
                    let dataGet = {
                        IdDnvtXe:
                            this.KyLenhTuyChon_ThongTinChung.ID_DnvtXe_DuocDiThay ||
                            this.KyLenhTuyChon_ThongTinChung.ID_DnvtXe,
                        IdDnvtTuyen: this.KyLenhTuyChon_ThongTinChung.ID_DnvtTuyen,
                    };
                    let rs = await this.$Events.RequestGet({
                        ServicesBE: "QuanLyThongTinLenh",
                        url: this.$t("urlQLTTLenh.LayHanPhuHieuCuaXeTrenTuyen"),
                        dataGet: dataGet,
                        msgSuccess: "",
                        msgError: "Lấy hạn phù hiệu tuyến thất bại!",
                        msgCatch: "Lấy hạn phù hiệu tuyến thất bại!",
                    });
                    if (rs.status && rs.data) {
                        this.KyLenhTuyChon_ThongTinChung.HanPhuHieuTuyen = this.$Helper.Common.getEndDay(
                            rs.data,
                        );
                    } else if (rs.status && !rs.data) {
                        this.$Helper.ThongBaoToast(
                            "warning",
                            "Chưa có phù hiệu của xe trên tuyến đang chọn!",
                        );
                    }
                } catch (error) {
                    console.log("🚀 ~ error:", error);
                }
            }
        },
    },
    created() {
        this.onValueChangedLayNgay();
    },
    mounted() {},
};
</script>
<style scoped>
:deep(.color-error-thong-tin-xe input.dx-texteditor-input) {
    color: #d10909;
}
:deep(.color-warning-thong-tin-xe input.dx-texteditor-input) {
    color: #fb8c00;
}
</style>
